
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'Translation' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

      <div class="row" [formGroup]="transaltionForm" 
      [ngClass]="{'text-left': lang == 'ar'}">
        <div class="col-md-12" *ngFor="let lang of langs">
          <div class="form-group">
            <label class="col-form-label">{{this.labelName+' ('+lang+')' | translate}}</label>
            <input 
            class="form-control"  type="text" 
             [placeholder]="'Enter translation' | translate" 
            [formControlName]="lang">
            <em
            *ngIf="
              transaltionForm.controls.Ar.touched &&
              transaltionForm.controls.Ar.errors?.pattern && lang==='Ar'
            "
            class="text-danger"
          >
            {{'arValidationErrorMsg' | translate}}
          </em>
          </div>
        </div>
      </div>
    </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-success" [disabled]="transaltionForm.invalid" (click)="submitTranslation()">{{'Ok' | translate}}</button>
    <button type="button" class="btn btn-outline-primary" (click)="modal.dismiss()">{{'Cancel' | translate}}</button>
  </div>
