
<div class="container-fluid p-0">
 
  <div >
    <table id="usersTable" class="table table-striped">
        <thead>
            <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Active</th>  
                <th>Company</th>              
                <th>phoneNumber</th>                                
                <th>Email</th>
                <th>Interests</th>
                <th>website</th>
                <th>Target sectors</th>
                <th>Annual sales</th>
                <th>ETA company value</th>
                <th>Required investment amount</th>
                <!-- Add more headers as needed -->
            </tr>
        </thead>
        <tbody>
            <!-- JavaScript will populate table rows here -->
        </tbody>
    </table>
</div>


  <div id="usersContainer"></div>
</div>
