import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { UcxService } from 'src/app/ucx/services/ucx.service';
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";



@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
  template: `
  <table>
      <thead>
          <th>Name</th>
      </thead>
      <tbody>
          <tr *ngFor="let hero of heroes; trackBy: trackHero" >
              <td>{{hero.name}}</td>
          </tr>
      </tbody>
  </table>
`

})
export class UsersListComponent implements OnInit {
  public users: any[];
  // users: Observable<any[]>;;
  users$: Observable<any>;
  constructor(private ucx: UcxService, private cd: ChangeDetectorRef, private router: Router) { }

  ngOnInit(): void {
    this.users$ = this.ucx.getAllUsers();

    this.ucx.getAllUsers().subscribe((res) => {

      this.users = res
      this.cd.detectChanges();
      this.renderUsers(this.users);
      console.log(this.users);
    });

    // this.users = this.ucx.getAllUsers();
    console.log(this.users);
  }


  // private renderUsers(users: any[]): void {
  //   this.users = users;
  //   this.cd.detectChanges();
  //   const container = document.getElementById('usersContainer');
  //   // Clear the container before adding new user data
  //   container.innerHTML = '';

  //   users.forEach(user => {
  //     const userDiv = document.createElement('div');
  //     userDiv.className = 'row';
  //     userDiv.innerHTML = `<p>${user.name} - ${user.email}  ${user.active} </p>`;
  //     container.appendChild(userDiv);
  //   });
  // }

  private renderUsers(users) {
    const tableBody = document.getElementById('usersTable').getElementsByTagName('tbody')[0];

    users.forEach(user => {
      let row = tableBody.insertRow();

      let cellId = row.insertCell(0);
      cellId.textContent = user.id;

      let cellName = row.insertCell(1);
      cellName.textContent = user.name;

      let cellActive = row.insertCell(2);
      cellActive.textContent = user.active;

      let cellCompany = row.insertCell(3);
      cellCompany.textContent = user.companyName;

      let cellphoneNumber = row.insertCell(4);
      cellphoneNumber.textContent = user.phoneNumber;

      let cellEmail = row.insertCell(5);
      cellEmail.textContent = user.email;

      let cellinterests = row.insertCell(6);
      cellinterests.textContent = user.interests;

      let cellwebsite_of_company = row.insertCell(7);
      cellwebsite_of_company.textContent = user.website_of_company;
      let target_sectors = '';
      if (typeof user.target_sectors === 'string') {
        const firstParse = JSON.parse(user.target_sectors);
        console.log('First parse:', firstParse);
        console.log('Type after first parse:', typeof firstParse);

        // Second parse to actually get the array
        const obj = JSON.parse(firstParse);
        console.log('Second parse (should be the array):', obj);

        // Now, this should return true if obj is an array
        console.log('Is array:', Array.isArray(obj));
        
        if (user.target_sectors && Array.isArray(obj)) {
          obj.forEach(item => {
            target_sectors += item.name;

          });
        }
    }
      let celltarget_sectors = row.insertCell(8);
      celltarget_sectors.textContent = target_sectors; //json decode 

      let cellannual_sales = row.insertCell(9);
      cellannual_sales.textContent = user.annual_sales; //json decode 
      
      let cellestimated_company_value = row.insertCell(10);
      cellestimated_company_value.textContent = user.estimated_company_value; //json decode 

            
      let cellrequired_investment_amount = row.insertCell(11);
      cellrequired_investment_amount.textContent = user.required_investment_amount; //json decode 

      
      // Add more cells as per your JSON data
    });
  }

  private createUserCard(user) {
    const userDiv = document.createElement('div');
    userDiv.classList.add('user-card');

    const name = document.createElement('p');
    name.textContent = 'Name: ' + user.name;
    userDiv.appendChild(name);

    // Add more fields similarly
    const email = document.createElement('p');
    email.textContent = 'Email: ' + user.email;
    userDiv.appendChild(email);

    // ... add other user properties as needed ...

    return userDiv;
  }


}
