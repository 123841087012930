
<li class="onhover-dropdown ucx-lang-li" >
	<a class="txt-dark" href="javascript:void(0)">
	  <h6 class="ucx-lang-color">{{lang}}</h6>
	</a>
	<ul class="language-dropdown onhover-show-div p-20 ucx-ul-lang">
	  <li><a href="javascript:void(0)" data-lng="en" (click)="changeLanguage('en')"><i
			class="flag-icon flag-icon-is"></i> <span class="mx-1">{{'English' | translate}}</span> </a></li>
	  <li><a href="javascript:void(0)" data-lng="ar" (click)="changeLanguage('ar')"><i
			class="flag-icon flag-icon-eg"></i> <span class="mx-1">{{'Arabic' | translate}}</span></a></li>
	</ul>
  </li>
<div class="page-wrapper" *ngIf="!showActivationForm && !showResetPassForm">
	<div class="auth-bg">
		<div class="authentication-box">
			<div class="text-center"><img src="assets/images/ucx/ucx_logo.png" alt="" class="ucx-logo"></div>
			<div class="card mt-4">
				<div class="card-body">
					<div class="text-center">
						<h4>{{'LOGIN' | translate}}</h4>
						<div class="d-sm-block text-center">
							<span class="mr-2">{{"Don't have an account?" | translate}}</span>
							<a routerLink="/authentication/register" class="font-size-16 kit__utils__link">
							  {{'Sign up' | translate}}
							</a>
						  </div>
					</div>
					<form class="theme-form" [formGroup]="loginForm" *ngIf="!newUser">
						<div class="form-group">
							<label class="col-form-label pt-0">{{'Email' | translate}}</label>
							<input class="form-control" formControlName="email" type="email" required="" [placeholder]="'Please enter your email' | translate"
							[ngClass]="{'input-error': loginForm.controls.email.touched &&  loginForm.controls.email.invalid}">
							<em *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required"
								class="text text-danger mt-1">
								{{'Email is required' | translate}}
						</em>
							<em *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email"
								class="text text-danger mt-1">
								{{'Invalid Email' | translate}}
					</em>
						</div>
						<div class="form-group">
							<label class="col-form-label">{{'Password' | translate}}</label>
							<input class="form-control" type="password" formControlName="password" required="" [placeholder]="'Please enter your password' | translate"
							[ngClass]="{'input-error': loginForm.controls.password.touched &&  loginForm.controls.password.invalid}">
							<em *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required"
								class="text text-danger mt-1">
								{{'Password is required' | translate}}
							</em>
						</div>
						<div class="checkbox p-0">
							<p style="cursor: pointer; color: blue;" (click)="showResetPassword()">{{'Forget Password ?' | translate}}</p>
						</div>
						<div class="form-group form-row mt-3 mb-0">
							<button class="btn btn-primary btn-block btn-lg"
								[class.loader--text]="authService.showLoader"
								[disabled]="!loginForm.valid || authService.showLoader" (click)="login(loginForm.value)"
								type="submit"><span>{{ authService.showLoader ? '' : 'Login' | translate}}

								</span>
							</button>
						</div>
						<!-- <div class="login-divider"></div>
						<div class="social mt-3">
							<div class="form-group btn-showcase d-flex">
								<button class="btn social-btn btn-fb d-inline-block" (click)="loginFacebook()">
									<i class="fa fa-facebook"></i>
								</button>
								<button class="btn social-btn btn-twitter d-inline-block" (click)="loginTwitter()">
									<i class="fa fa-twitter"></i>
								</button>
								<button class="btn social-btn btn-google d-inline-block" (click)="loginGoogle()">
									<i class="fa fa-google"></i>
								</button>
							</div>
						</div> -->
					</form>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="page-wrapper" *ngIf="showActivationForm">
	<div class="auth-bg">
		<div class="authentication-box">
			<div class="text-center"><img src="assets/images/ucx/ucx_logo.png" alt="" class="ucx-logo"></div>
			<div class="card mt-4">
				<div class="card-body">
					<div class="text-center">
						<h4>Verify Email</h4>
						<div class="d-sm-block text-center">
							Please enter code that you recieved to verify your email
						  </div>
						  <a (click)="resendEmail()" class="text-primary cursor-pointer">Resend Activation Code</a>
					</div>
					<form class="theme-form" [formGroup]="verifyForm">
						<div class="form-group">
							<label class="col-form-label pt-0">Code</label>
							<input class="form-control" formControlName="activationCode" type="text" required
							[ngClass]="{'input-error': verifyForm.controls.activationCode.touched &&  verifyForm.controls.activationCode.invalid}">
							<em *ngIf="verifyForm.controls.activationCode.touched && verifyForm.controls.activationCode.errors?.required"
								class="text text-danger mt-1">
								Code is required
						      </em>
						</div>

						<div class="form-group form-row mt-3 mb-0">
							<button class="btn btn-primary btn-block btn-lg"
								[disabled]="verifyForm.invalid" (click)="veifyCode(verifyForm.value)"
								type="submit"><span>
									Verify Code

								</span>
							</button>
						</div>

					</form>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="page-wrapper" *ngIf="showResetPassForm">
	<div class="auth-bg">
		<div class="authentication-box">
			<div class="text-center"><img src="assets/images/ucx/ucx_logo.png" alt="" class="ucx-logo"></div>
			<div class="card mt-4">
				<div class="card-body">
					<div class="text-center">
						<h4>{{'Reset Your Password' | translate}}</h4>
						<div class="d-sm-block text-center">
							{{'Please enter code that you recieved on your email to reset your password' | translate}}
						  </div>
					</div>
					<form class="theme-form" [formGroup]="restPassForm">
						<div class="form-group">
							<div class="form-row col-md-12">
								<label class="col-form-label pt-0">{{'Code' | translate}}</label>
								<input class="form-control" formControlName="code" type="text" required
								[ngClass]="{'input-error': restPassForm.controls.code.touched &&  restPassForm.controls.code.invalid}">
								<em *ngIf="restPassForm.controls.code.touched && restPassForm.controls.code.errors?.required"
									class="text text-danger mt-1">
									{{'Code is required' | translate}}
								  </em>
							</div>
							<div class="form-row col-md-12 my-2">
								<label class="col-form-label pt-0">{{'New Password' | translate}}</label>
								<input (ngModelChange)="this.restPassForm.controls.confirmPassword.reset()" class="form-control" formControlName="password" type="password" required
								[ngClass]="{'input-error': restPassForm.controls.password.touched &&  restPassForm.controls.password.invalid}">
								<em *ngIf="restPassForm.controls.password.touched && restPassForm.controls.password.errors?.required"
									class="text text-danger mt-1">
									{{'Password is required' | translate}}
								  </em>
								  <em *ngIf="restPassForm.controls.password.touched &&
								  restPassForm.controls.password.errors?.pattern" class="text-danger">
								  Password must be at least 8 letters that contain a capital letter, small letter,
								  a symbol and a number
								  </em>
							</div>
							<div class="form-row col-md-12">
								<label class="col-form-label pt-0">{{'Confirm Password' | translate}}</label>
								<input (ngModelChange)="onChange($event)" class="form-control" formControlName="confirmPassword" type="password" required
								[ngClass]="{'input-error': restPassForm.controls.confirmPassword.touched &&  restPassForm.controls.confirmPassword.invalid}">
								<em *ngIf="restPassForm.controls.confirmPassword.touched &&
								restPassForm.controls.confirmPassword.errors?.match" class="text-danger">
								{{'The two passwords are not the same' | translate}}
								</em>
							</div>
						</div>

						<div class="form-group form-row mt-3 mb-0">
							<button class="btn btn-primary btn-block btn-lg"
								[disabled]="restPassForm.invalid" (click)="resetPassword(restPassForm.value)"
								type="submit"><span>
									{{'Reset Password' | translate}}

								</span>
							</button>
						</div>

					</form>
				</div>
			</div>
		</div>
	</div>
</div>
