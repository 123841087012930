import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { SharedDataService } from '../services/shared-data.service';
import { UcxService } from '../services/ucx.service';

@Component({
  selector: 'app-translation-modal',
  templateUrl: './translation-modal.component.html',
  styleUrls: ['./translation-modal.component.scss']
})
export class TranslationModalComponent implements OnInit {
  transaltionForm:FormGroup;
  lang: string;
  propertyName:string;
  labelName:string;
  langs = ['Ar']
  // ,'Sp','Po','Fr'
  constructor(private fb:FormBuilder,private toastr: ToastrService,private translate:TranslateService,
    public modal: NgbActiveModal,private share:SharedDataService,private ucx:UcxService) { 
      this.transaltionForm = this.fb.group({
        Ar:['',Validators.pattern(this.share.arValidationPattern)],
        // Sp:[''],
        // Po:[''],
        // Fr:['']
      })
    }

  ngOnInit(): void {
    this.lang = this.translate.currentLang;
   let oldtranslation = this.share.translation.find(translation=>translation.propertyName === this.propertyName)
   if(oldtranslation){
     this.transaltionForm.patchValue({
       Ar:oldtranslation.translation.Ar,
      //  Sp:oldtranslation.translation.Sp,
      //  Po:oldtranslation.translation.Po,
      //  Fr:oldtranslation.translation.Fr,
     })
   }
 

  }

  

  submitTranslation(){
    let translationObj = {
      "propertyName":this.propertyName,
      "translation":this.transaltionForm.value
    }
    this.modal.close(translationObj);
  }
}
