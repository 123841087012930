import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { retry, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UcxService {

  constructor(private http:HttpClient) { }

  login(user): Observable<any> {
    return this.http.post('/login', user).pipe(take(1))
  }
  activeUser(code): Observable<any> {
    return this.http.post('/activate', code).pipe(take(1))
  }

  register(user):Observable<any>{
    return this.http.post('/register', user).pipe(take(1))
  }

  getAllUsers():Observable<any>{
    return this.http.get('/listUsers').pipe(take(1))
  }
  getAllSectors():Observable<any>{
    return this.http.get('/sector').pipe(take(1))
  }
  getlookupDetails(num):Observable<any>{
    return this.http.get('/lookupDetails/'+num).pipe(take(1))
  }
  getCount():Observable<any>{
    return this.http.get('/count').pipe(take(1))
  }
  getNotifications(userId):Observable<any>{
    return this.http.get(`/notifications/${userId}`).pipe(take(1))
  }
  changeNotificationStatus(notificationId,body):Observable<any>{
    return this.http.put(`/notifications/${notificationId}`,body).pipe(take(1))
  }
  getCities(countryId):Observable<any>{
    return this.http.get('/cities/'+countryId).pipe(take(1))
  }
  Search(body):Observable<any>{
    return this.http.post('/search',body).pipe(take(1))
  }
  getAllInvestors():Observable<any>{
    return this.http.get('/investors').pipe(take(1))
  }
  insertInvestorDirectors(user_id,investor_id,body):Observable<any>{
    return this.http.post(`/users/${user_id}/investors/${investor_id}/managements`,body).pipe(take(1))
  }
  updateInvestorDirectors(user_id,investor_id,body):Observable<any>{
    return this.http.put(`/users/${user_id}/investors/${investor_id}/managements`,body).pipe(take(1))
  }
  insertInvestorPortfolio(user_id,investor_id,body):Observable<any>{
    return this.http.post(`/users/${user_id}/investors/${investor_id}/portfolio`,body).pipe(take(1))
  }
  updateInvestorPortfolio(user_id,investor_id,body):Observable<any>{
    return this.http.put(`/users/${user_id}/investors/${investor_id}/portfolio`,body).pipe(take(1))
  }
  watchlist(body:{user_id: number,company_id: number}):Observable<any>{
    return this.http.post('/watchlist',body).pipe(take(1))
  }
  getDocuments(user_id,company_id):Observable<any>{
    return this.http.get(`/users/${user_id}/attachments/${company_id}`).pipe(take(1));
  }
  getWatchList(user_id):Observable<any>{
    return this.http.get('/watchlist/'+user_id).pipe(take(1))
  }
  getInterestList(investeeId):Observable<any>{
    return this.http.get(`/users/${investeeId}/interests`).pipe(take(1))
  }
  getAllAdvisors():Observable<any>{
    return this.http.get('/advisors').pipe(take(1))
  }
  deleteAdvisor(id):Observable<any>{
    return this.http.delete('/deleteAdvisor/'+id).pipe(take(1))
  }
  getAllCountries():Observable<any>{
    return this.http.get('/countries').pipe(take(1))
  }
  getChatMessages(interestId){
    return this.http.get('/interestDialog/'+interestId).pipe(take(1))
  }
  sendMessage(message:{interest_id:number,dialog:string,sender_type:number}){
    return this.http.post('/interestDialog',message).pipe(take(1))
  }
  deleteCompany(id):Observable<any>{
    return this.http.delete(`/company/${id}`).pipe(take(1))
  }
  toggleInterest(userId,InteresId,status):Observable<any>{
    return this.http.put(`/users/${userId}/submitinterest/${InteresId}`,{'status':status}).pipe(take(1))
  }
  deleteInvestor(id):Observable<any>{
    return this.http.delete(`/deleteInvestor/${id}`).pipe(take(1))
  }
  getAllCurrencies():Observable<any>{
    return this.http.get('/currencies').pipe(take(1))
  }
  submitInetrest(userId, investorId,investeeId,body):Observable<any>{
    return this.http.post(`/investor/${userId}/${investorId}/investees/${investeeId}/submitinterest`,body).pipe(take(1))
  }


  getUserInterests(userId):Observable<any>{
    return this.http.get(`/users/${userId}/submitinterest`).pipe(take(1))
  }


  sendCodeToResetPassword(email):Observable<any>{
    return this.http.post('/password/recovery',{'email':email}).pipe(take(1))
  }
  resetPassword(json):Observable<any>{
    return this.http.post('/password/reset',json).pipe(take(1))
  }
  resendEmail(json):Observable<any>{
    return this.http.post('/resendActivationEmail',json).pipe(take(1))
  }
  getAllCompanies():Observable<any>{
    return this.http.get('/company').pipe(take(1))
  }
  getInvesteeAttachmentsTypes():Observable<any>{
    return this.http.get('/investeeAttachmentsTypes').pipe(take(1))
  }
  getCompanyData(id,investeeId):Observable<any>{
    return this.http.get(`/users/${id}/investees/${investeeId}`).pipe(take(1))
  }
  companyBasicInfo(company,id):Observable<any>{
    return this.http.post(`/users/${id}/investees`, company).pipe(take(1))
  }
  deleteInvestorPortfolio(user_id,portfolio_id):Observable<any>{
    return this.http.delete(`/users/${user_id}/portfolio/${portfolio_id}`).pipe(take(1))
  }
  deleteInvestorDirector(user_id,management_id):Observable<any>{
    return this.http.delete(`/users/${user_id}/managements/${management_id}`).pipe(take(1))
  }
  insertInvestor(investor,id):Observable<any>{
    return this.http.post(`/users/${id}/investor`, investor).pipe(take(1))
  }
  updateInvestor(userId,investorId,body):Observable<any>{
    return this.http.put(`/users/${userId}/investor/${investorId}`,body).pipe(take(1))
  }
  getInvestorDirectorsAndPortfolio(investorId):Observable<any>{
    return this.http.get(`/investorsDirectors/${investorId}`).pipe(take(1))
  }
  getSubSectors(sectors):Observable<any>{
    return this.http.post('/subsectorsBasedSector',sectors).pipe(take(1))
  }
  insertAdvisor(advisor,id):Observable<any>{
    return this.http.post(`/users/${id}/advisor`, advisor).pipe(take(1))
  }
  updateAdvisor(advisor,id,advisorId):Observable<any>{
    return this.http.put(`/users/${id}/advisor/${advisorId}`, advisor).pipe(take(1))
  }
  // updateBasicInfo(company,id,investeeId):Observable<any>{
  //   return this.http.put(`/users/${id}/investees/${investeeId}`, company).pipe(take(1))
  // }
  updateBasicInfo(body,userId,investeeId):Observable<any>{
    return this.http.put(`/users/${userId}/basicInfo/${investeeId}`, body).pipe(take(1))
  }
  
  companyCapitalInfo(company,userId,investeeId):Observable<any>{
    return this.http.post(`/users/${userId}/investees/${investeeId}/capitals`, company).pipe(take(1))
  }
  updateCapitalInfo(company,userId,investeeId,capitalId):Observable<any>{
    return this.http.put(`/users/${userId}/investees/${investeeId}/capitals/${capitalId}`, company).pipe(take(1))
  }
  companyDirectorsInfo(company,userId,investeeId):Observable<any>{
    return this.http.post(`/users/${userId}/investees/${investeeId}/directors`, company).pipe(take(1))
  }
  updateDirectorsInfo(company,userId,investeeId,directorId):Observable<any>{
    return this.http.put(`/users/${userId}/investees/${investeeId}/directors/${directorId}`, company).pipe(take(1))
  }
  companyShareholdes(company,userId,investeeId):Observable<any>{
    return this.http.post(`/users/${userId}/investees/${investeeId}/ownerships`, company).pipe(take(1))
  }
  updateShareholdes(company,userId,investeeId,shareholderId):Observable<any>{
    return this.http.put(`/users/${userId}/investees/${investeeId}/ownerships/${shareholderId}`, company).pipe(take(1))
  }
  companyAuditor(company,userId,companyId):Observable<any>{
    return this.http.post(`/users/${userId}/investees/${companyId}/auditors`, company).pipe(take(1))
  }
  updateCompanyAuditor(company,userId,companyId,ParentauditorId,auditorId):Observable<any>{
    return this.http.put(`/users/${userId}/investees/${companyId}/auditors/${ParentauditorId}/${auditorId}`, company).pipe(take(1))
  }
  companySubsidiaries(company,userId,companyId):Observable<any>{
    return this.http.post(`/users/${userId}/company/${companyId}/subsidiaries`, company).pipe(take(1))
  }
  updateCompanySubsidiaries(company,userId,investeeId,companyId):Observable<any>{
    return this.http.put(`/users/${userId}/company/${investeeId}/subsidiaries/${companyId}`, company).pipe(take(1))
  }
  insertCompanyBasicDataAndPorposal(userId,body):Observable<any>{
    return this.http.post(`/users/${userId}/companyBasicInfo`,body).pipe(take(1));
  }
  companyFinancial(company,userId,companyId):Observable<any>{
    return this.http.post(`/users/${userId}/investees/${companyId}/incomes`, company).pipe(take(1))
  }
  companyBalanceStatements(company,userId,companyId):Observable<any>{
    return this.http.post(`/users/${userId}/investees/${companyId}/balancestatements`, company).pipe(take(1))
  }
  updateCompanyFinancial(company,userId,investeeId,fId):Observable<any>{
    return this.http.put(`/users/${userId}/investees/${investeeId}/incomes/${fId}`, company).pipe(take(1))
  }
  updateBalanceStatements(company,userId,investeeId,bId):Observable<any>{
    return this.http.put(`/users/${userId}/investees/${investeeId}/balancestatements/${bId}`, company).pipe(take(1))
  }
  getCompanyProposal(userId,investeeId):Observable<any>{
    return this.http.get(`/users/${userId}/investmentproposals/${investeeId}`).pipe(take(1))
  }
  companyProposal(company,userId,investeeId):Observable<any>{
    return this.http.post(`/users/${userId}/investees/${investeeId}/investmentproposals`, company).pipe(take(1))
  }
  updateCompanyProposal(company,userId,investeeId,propId,proposal_transaltion_id):Observable<any>{
    return this.http.put(`/users/${userId}/investees/${investeeId}/investmentproposals/${proposal_transaltion_id}/${propId}`, company).pipe(take(1))
  }
  deleteCompayProposal(userId,proposalId):Observable<any>{
    return this.http.delete(`/users/${userId}/investmentproposals/${proposalId}`).pipe(take(1))
  }
}
