import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from '../../shared/services/firebase/auth.service';
import { UcxService } from 'src/app/ucx/services/ucx.service';
import { SharedDataService } from 'src/app/ucx/services/shared-data.service';
import { ToastrService } from 'ngx-toastr';
import { CustomizerService } from 'src/app/shared/services/customizer.service';
import { TranslateService } from '@ngx-translate/core';

type UserFields = 'email' | 'password';
type FormErrors = { [u in UserFields]: string };

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public newUser = false;
  public user: firebase.User;
  public loginForm: FormGroup;
  public verifyForm: FormGroup;
  public restPassForm: FormGroup;
  public formErrors: FormErrors = {
    'email': '',
    'password': '',
  };
  public errorMessage: any;
  showActivationForm: boolean = false;
  showResetPassForm: boolean = false;
  passValidation: string | RegExp = /(?=(.*[0-9]))(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/;
  lang: string = 'EN';


  constructor(public authService: AuthService,
    public customize: CustomizerService,
    private translate: TranslateService,
    private afauth: AngularFireAuth, private fb: FormBuilder,private toastr:ToastrService,
    private router: Router,private ucx:UcxService,private share:SharedDataService) {
    this.lang = this.translate.currentLang ? this.translate.currentLang : 'EN' ;
    this.loginForm = fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
    this.verifyForm = fb.group({
      activationCode: ['', Validators.required]
    });
    this.restPassForm = fb.group({
      code: ['', Validators.required],
      password: ['', [Validators.required,Validators.pattern(this.passValidation)]],
      confirmPassword: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.translate.use(this.share.getLang().value)
    this.lang =  this.share.getLang().value.toLocaleUpperCase();
  }

  public changeLanguage(lang:string) {
   this.share.assignLang(lang.toLocaleLowerCase());
   this.translate.use(this.share.getLang().value);
    lang === 'ar' ? this.customizeLayoutType('rtl') : this.customizeLayoutType('ltr')  ;
    this.lang =  lang.toLocaleUpperCase();
  }

   // Customize Layout Type
    customizeLayoutType(val) {
      this.customize.setLayoutType(val)
    }

  // Login With Google
  loginGoogle() {
    this.authService.GoogleAuth();
  }

  // Login With Twitter
  loginTwitter(): void {
    this.authService.signInTwitter();
  }

  // Login With Facebook
  loginFacebook() {
    this.authService.signInFacebok();
  }

  onChange(event) {
    if (event === this.restPassForm.value.password) {
      this.restPassForm.controls.confirmPassword.setErrors({match:false})
      this.restPassForm.controls.confirmPassword.updateValueAndValidity();
    } else {
      this.restPassForm.controls.confirmPassword.setErrors({match:true})
    }
  }
  showResetPassword(){
    if(this.loginForm.value.email === ''){
      this.toastr.error('You must enter your email to reset your password !!')
    }else{
      this.ucx.sendCodeToResetPassword(this.loginForm.value.email).subscribe(res=>{
        res.status === 406 ? this.toastr.warning(res.message) :this.showResetPassForm = true;
      },err=>{
        console.log(err);
        this.toastr.error(err.error.message)
      })
    }
  }

  resetPassword(param){
    let json = {
      "secret_code": param.code,
      "password": param.password
    }
    this.ucx.resetPassword(json).subscribe(res=>{
        this.toastr.success(res.message);
        this.showResetPassForm = false;
        this.showActivationForm = false;
    },err=>{
      console.log(err);
      this.toastr.error(err.error.message);
      this.showResetPassForm = true;
    })
  }
  // Simple Login
  login() {
    this.ucx.login(this.loginForm.value).subscribe(user=>{
      if(user.status === 406){
        // this.showActivationForm = true;
        this.toastr.error('your account is not active', 'Error');
      }else{
        localStorage.setItem('isLoggined','true')
        localStorage.setItem('ucxToken',user.accessToken)
        this.share.assignToken(user.accessToken)
         this.share.assignUser(user.user_info)
         this.share.assignUserInvestors(user.user_investors)
         this.share.assignUserIndividualInvestorId(user.user_info.individual_investor)
         this.share.userCompanies = user.user_companies.map(u=>u.id);
         this.share.userInterests = user.interests;
         this.toastr.success('welcome to UCX','Awesome');
         user._found_related_data === 1 ? this.router.navigate(['ucx/welcome']):this.checkUserTypeBeforNavigation(user.user_info.interests);
         this.getNotifications(user.user_info.id);
      }
    },err=>{
      console.log(err);
      this.toastr.error(err.error.error, err.error.message);
    })
  }

  getNotifications(userId){
    this.ucx.getNotifications(userId).subscribe(notifications=>{
      this.share.assignNotifications(notifications);
    })
  }
  checkUserTypeBeforNavigation(type){  
    switch(type){
      case 'Investor':{
        this.router.navigate(['/ucx/insert-investment-file/institutional']);
        break;
      }
      case 'Selling shareholder':{
        this.router.navigate(['/ucx/insert-investment-file/individual']);
        break;
      }
      case 'Advisor':{
        this.router.navigate(['/ucx/insert-advisor']);
        break;
      }
      case 'Company Looking For Investors ':{
        this.router.navigate(['/ucx/insert-company']);
        break;
      }
      default:{
        this.router.navigate(['ucx/welcome']);
        break;
      }
    }
  }
  veifyCode(code){
    this.ucx.activeUser(code).subscribe(verify=>{
      this.toastr.success(verify.message, 'Awesome');
      this.showActivationForm = false;
    },err=>{
      console.log(err);
      this.showActivationForm = true;
      this.toastr.error(err.error.error, err.error.message);
    })
  }

  resendEmail(){
    let json = {"email": this.loginForm.value.email}
    this.ucx.resendEmail(json).subscribe(result => {
       if(result.status === 406){
        this.toastr.error(result.message);
       }else{
        this.toastr.success(result.message);
       }
    })
  }
}
