import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { ContentLayoutComponent } from './shared/components/layout/content-layout/content-layout.component';
import { FullLayoutComponent } from './shared/components/layout/full-layout/full-layout.component';
import { content } from "./shared/routes/content-routes";
import { full } from './shared/routes/full.routes';
import { AdminGuard } from './shared/guard/admin.guard';
import { UsersListComponent } from './pages/user-list/users-list.component'; // Import the UsersListComponent
import { CommonModule } from '@angular/common';
 /// check
const routes: Routes = [
  {
    path: 'all_users9876521877',
    component: UsersListComponent,
  },
  {
    path: '',
    redirectTo: 'authentication',
    pathMatch: 'full'
  },
  {
    path: 'authentication',
    loadChildren: () => import('./pages/authentication/authentication.module').then(m => m.AuthenticationModule),
  },
  {
    path: 'dashboard',
    component: ContentLayoutComponent,
    canActivate: [AdminGuard],
    loadChildren: () => import('../app/components/dashboard/dashboard.module').then(m => m.DashboardModule),
    data: {
      breadcrumb: "Dashboard"
    }
  },
  {
    path: 'ucx',
    component: ContentLayoutComponent,
    canActivate: [AdminGuard],
    loadChildren: () => import('../app/ucx/ucx.module').then(m => m.UcxModule),
    data: {
      breadcrumb: "UCX"
    }
  },
  // {
  //   path: 'dashboard/default',
  //   component: ContentLayoutComponent,
  //   canActivate: [AdminGuard],
  //   children: content
  // },
  // {
  //   path: '',
  //   component: FullLayoutComponent,
  //   canActivate: [AdminGuard],
  //   children: full
  // },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [CommonModule ,RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy',
    useHash: true
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
