import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BehaviorSubject, Observable, of } from "rxjs";
import { TranslationModalComponent } from "../translation-modal/translation-modal.component";

@Injectable({
  providedIn: "root",
})
export class SharedDataService {
  accessToken: any;
  private token = new BehaviorSubject("");
  private lang = new BehaviorSubject('en');
  private user = new BehaviorSubject({});
  private userInvestors = new BehaviorSubject([]);
  private notifications = new BehaviorSubject([]);
  public enValidationPattern = /^[a-zA-Z0-9-','.'&'!'@'-'$'%'(')' \n]+$/;
  public arValidationPattern = /^[\u0621-\u064A0-9','؛'.'&'!'@'$'%'(')' \n ]+$/;
  public emailValidation: string | RegExp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  public phoneValidation: string | RegExp = /(^[0-9]{7,15}$)/;
  public passValidation: string | RegExp = /(?=(.*[0-9]))(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/;
 
  private userIndividualInvestorId = new BehaviorSubject(0);
  private itemDetails = new BehaviorSubject({});
  public translation:any [] = [];
  public userCompanies: any[] = [];
  public userInterests: any[] = [];
  public displayModes: { edit: boolean; view: boolean; insert: boolean } = {
    edit: false,
    view: false,
    insert: true,
  };
  public targeCompany: { companyId: number; investeeId: number,company:any };
  allCompanies: any[]=[];
  investor:any;
  updatedItem:any;
  interestDetails:{interestId:number,interestName:string,sender_type:number}={interestId:null,interestName:null,sender_type:null}
  constructor(private modalService:NgbModal) {}

  getToken() {
    return this.token;
  }
  getLang() {
    return this.lang;
  }
  assignToken(message: string) {
    this.token.next(message);
  }
  assignLang(message: string) {
    this.lang.next(message);
  }
  getUser() {
    return this.user;
  }
  getUserInvestors() {
    return this.userInvestors;
  }
  getNotifications() {
    return this.notifications;
  }
  getUserIndividualInvestorId() {
    return this.userIndividualInvestorId;
  }
  getItemDetails() {
    return this.itemDetails;
  }
  assignUser(message: {}) {
    this.user.next(message);
  }
  assignNotifications(notifications: []) {
    this.notifications.next(notifications);
  }
  assignUserInvestors(message: []) {
    this.userInvestors.next(message);
  }

  addUserInterests(id:any) {
    this.userInterests.push(id);
  }
  assignUserIndividualInvestorId(message: number) {
    this.userIndividualInvestorId.next(message);
  }
  assignItemDetails(message: {}) {
    this.itemDetails.next(message);
  }

  openTranslationModal(propertyName,labelName) {
    const conf = this.modalService.open(TranslationModalComponent, { size: 'lg', backdrop: 'static' });
    conf.componentInstance.propertyName = propertyName;
    conf.componentInstance.labelName = labelName;
    conf.result.then((result) => {
      //ok call back
      const existed = this.translation.some(item=>item.propertyName === propertyName);
      if(existed){
        let item = this.translation.find(item=>item.propertyName === propertyName);
        item.translation = result.translation;
      }else{
        this.translation.push(result);
      }
      console.log("this.translation",this.translation);
      
    }, (reason) => {
      //dismiss call back
      console.log("reason",reason);

    });
  }

  checkExsitedTranslation(propertyName){
    return this.translation.some(item=>item.propertyName === propertyName);
  }
}
